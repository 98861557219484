import { template as template_b739239161b44119a3767eaf02394afa } from "@ember/template-compiler";
const FKLabel = template_b739239161b44119a3767eaf02394afa(`
  <label for={{@fieldId}} ...attributes>
    {{yield}}
  </label>
`, {
    eval () {
        return eval(arguments[0]);
    }
});
export default FKLabel;
